import { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import { Slide } from '@mui/material'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { useCoBrand } from '../../hooks/useCoBrand'
import { AuthAttributes } from '../../models/auth.model'
import { useLoginMutation } from '../../redux/apis/authApi'
import { Header } from '../common/header/header'
import LegalCopy from '../common/LegalCopy'
import { StyledFormContainer, StyledSigninContainer } from '../common/styled/StyledComponents'
import { HomeDepotSaml } from '../saml/HomeDepotSaml'

import { Email } from './email'
import { Password } from './password'

const FormContainer = styled('div')`
  overflow: hidden;
  display: flex;
  position: relative;
  min-height: 200px;
  width: 396px;
`

const initialFormValues: AuthAttributes = {
  email: '',
  password: '',
}

export const SignIn = () => {
  const intl = useIntl()
  const [errors, setErrors] = useState<string>('')
  const [authLogin, { status, error }] = useLoginMutation()
  const [emailActive, setEmailActive] = useState<boolean>(true)
  const [searchParams] = useSearchParams()
  const queryError = searchParams.get('error')

  const signInSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'form_errors.invalid_email' }))
      .required(intl.formatMessage({ id: 'form_errors.email_required' })),
    password: Yup.string().required(),
  })

  const handleSubmit = (data: AuthAttributes, { setSubmitting }: FormikHelpers<AuthAttributes>) => {
    authLogin(data)
    setSubmitting(false)
  }

  const containerRef = useRef(null)
  const [, { isHdus }] = useCoBrand()

  useEffect(() => {
    if (status === 'fulfilled') {
      const nextPage = new URLSearchParams(window.location.search).get('next')
      window.location.href = nextPage || '/'
    }
  }, [status])

  useEffect(() => {
    if (error) {
      setErrors(intl.formatMessage({ id: 'signin.invalid_credentials' }))
    }
  }, [error])

  return (
    <Box width={396} margin="auto">
      <StyledSigninContainer>
        <Header />
        {(queryError || errors) && (
          <Stack mt={2} mb={2} spacing={2} data-testid="signin-errors">
            {errors && <Alert severity="error"> {errors}</Alert>}
            {queryError && (
              <Alert severity="error">
                <FormattedMessage id="signin.saml.authentication-error" />
              </Alert>
            )}
          </Stack>
        )}
        <StyledFormContainer>
          {isHdus && (
            <Link href="/core/saml/login/" data-testid="home-depot-login">
              <HomeDepotSaml />
            </Link>
          )}
          {isHdus && (
            <>
              <Box sx={{ mt: 1 }}>
                <Typography component="p" variant="body1">
                  <FormattedMessage id="signin.heading" />
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography component="p" variant="body1">
                  <FormattedMessage
                    id="signin.account_request"
                    values={{
                      link: (
                        <a href="https://vendormarketing.homedepot.com/orangeapronmedia">
                          {intl.formatMessage({ id: 'common.click_here' })}
                        </a>
                      ),
                    }}
                  />
                </Typography>
              </Box>
            </>
          )}
          {!isHdus && (
            <>
              <Formik initialValues={initialFormValues} onSubmit={handleSubmit} validationSchema={signInSchema}>
                <FormContainer ref={containerRef}>
                  <Slide direction="down" in={emailActive} container={containerRef.current}>
                    <Email setActive={setEmailActive} />
                  </Slide>
                  <Slide direction="up" in={!emailActive} container={containerRef.current}>
                    <Password setActive={setEmailActive} />
                  </Slide>
                </FormContainer>
              </Formik>
              <Box sx={{ mt: 1 }}>
                <Typography component="p" variant="body1">
                  <FormattedMessage id="signin.heading" />
                </Typography>
              </Box>
            </>
          )}

          {!isHdus && (
            <Grid container gap={2} mt={2}>
              <Grid item xs>
                <FormattedMessage id="signin.forgot_password">
                  {(txt) => (
                    <Link href="/auth/forgot-password" data-testid={'forgot-password-link'}>
                      {txt}
                    </Link>
                  )}
                </FormattedMessage>
              </Grid>
              <Grid item>
                <FormattedMessage id="signin.signup">
                  {(txt) => (
                    <Link href="/auth/signup" data-testid={'signup-link'}>
                      {txt}
                    </Link>
                  )}
                </FormattedMessage>
              </Grid>
            </Grid>
          )}
        </StyledFormContainer>
        <LegalCopy />
      </StyledSigninContainer>
    </Box>
  )
}
