import React from 'react'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useCookie } from 'react-use'
import { ThemeProvider } from '@mui/material/styles'
import flatten from 'flat'

import { YupErrorMapProvider } from '@lib/forms'
import { config, vantageTheme } from '@lib/ui-shared'
import { ForgotPasswordPage } from '@login/routes/ForgotPasswordPage'

import { ForgotPasswordKey } from '../components/forgot-password/forgot-password-key'
import { SignIn } from '../components/signIn/signIn'
import * as defaultMessages from '../i18n/locales/default.json'
import { Configuration, updateConfig } from '../redux/slices/configurationSlice'
import { setupStore } from '../redux/store'
import { SignUpPage } from '../routes/SignUpPage'

const copy: any = {
  en: flatten(defaultMessages),
}

declare global {
  interface Window {
    config: any
  }
}

if (!window.config) {
  window.config = {
    ...config,
  }
}

export type AppProps = {
  publicPath: string | undefined
}

export function App({ publicPath }: AppProps) {
  const [csrfToken] = useCookie('csrftoken')
  const configurationType: Configuration = {
    publicPath,
    csrfToken: csrfToken || '',
  }

  const store = setupStore()

  store.dispatch(updateConfig(configurationType))

  return (
    <IntlProvider locale="en" messages={copy['en']}>
      <YupErrorMapProvider>
        <ThemeProvider theme={vantageTheme}>
          <Provider store={store}>
            <Routes>
              <Route path="auth/login" element={<SignIn />} />
              <Route path="auth/forgot-password/:resetKey" element={<ForgotPasswordKey />} />
              <Route path="auth/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="auth/signup" element={<SignUpPage />} />
              <Route path="*" element={<Navigate to={'/auth/login'} />} />
            </Routes>
          </Provider>
        </ThemeProvider>
        {/* END: routes */}
      </YupErrorMapProvider>
    </IntlProvider>
  )
}

export default App
