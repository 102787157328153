import { faker } from '@faker-js/faker'

import { MIN_PASSWORD_LENGTH } from '../components/PasswordRequirements'

/**
 * Test data for the password field
 */
export const mockPasswords = {
  tooShort: faker.internet.password({ length: MIN_PASSWORD_LENGTH - 1, prefix: 'Aa1!' }),
  noUppercase: faker.internet.password({ length: MIN_PASSWORD_LENGTH, prefix: 'a1!', pattern: /[a-z]/ }),
  noLowercase: faker.internet.password({ length: MIN_PASSWORD_LENGTH, prefix: 'A1!', pattern: /[A-Z]/ }),
  noNumber: faker.internet.password({ length: MIN_PASSWORD_LENGTH, prefix: 'Aa!', pattern: /[a-zA-Z]/ }),
  noSymbol: faker.internet.password({ length: MIN_PASSWORD_LENGTH, prefix: 'Aa1', pattern: /[a-zA-Z\d]/ }),
  valid: faker.internet.password({
    length: MIN_PASSWORD_LENGTH,
    pattern: /[\w\d()~!@#$%^&*=;:'",<>./?]/,
    prefix: 'Aa1!',
  }),
}
